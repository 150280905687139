<template>
  <div id="distance" class="distance-container"></div>
  <button
    :class="{ measure: true, 'measure--active': isMeasureActive }"
    @click="measureHandler"
  >
    <i class="fas fa-ruler"></i>
    <span class="measure__tooltip">{{
      isMeasureActive
        ? t('labels.measurement_tool.on')
        : t('labels.measurement_tool.off')
    }}</span>
  </button>
</template>
<script setup>
import { ref, watch } from 'vue'
import * as turf from '@turf/turf'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  mapRef: {
    type: Object,
    default: () => {}
  }
})

const isMeasureActive = ref(false)

// GeoJSON object to hold our measurement features
const geojson = {
  type: 'FeatureCollection',
  features: []
}

// Used to draw a line between points
const linestring = {
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates: []
  }
}

const measureHandler = () => {
  isMeasureActive.value = !isMeasureActive.value

  if (geojson.features.length) {
    geojson.features = []
    props.mapRef.map.getSource('geojson').setData(geojson)
  }

  // Clear the distance container to populate it with a new value.
  const distanceContainer = document.getElementById('distance')
  distanceContainer.innerHTML = ''

  props.mapRef.map.getCanvas().style.cursor = isMeasureActive.value
    ? 'crosshair'
    : ''
}

const setupMeasurementTool = map => {
  map.on('load', () => {
    map.addSource('geojson', {
      type: 'geojson',
      data: geojson
    })
    // Add styles to the map
    map.addLayer({
      id: 'measure-points',
      type: 'circle',
      source: 'geojson',
      paint: {
        'circle-radius': 5,
        'circle-color': '#099ee4'
      },
      filter: ['in', '$type', 'Point']
    })
    map.addLayer({
      id: 'measure-lines',
      type: 'line',
      source: 'geojson',
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#099ee4',
        'line-width': 2.5
      },
      filter: ['in', '$type', 'LineString']
    })
    map.on('click', e => {
      if (!isMeasureActive.value) return
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['measure-points']
      })

      // Remove the linestring from the group
      // so we can redraw it based on the points collection.
      if (geojson.features.length > 1) geojson.features.pop()

      // Clear the distance container to populate it with a new value.
      const distanceContainer = document.getElementById('distance')
      distanceContainer.innerHTML = ''

      // If a feature was clicked, remove it from the map.
      if (features.length) {
        const id = features[0].properties.id
        geojson.features = geojson.features.filter(
          point => point.properties.id !== id
        )
      } else {
        const point = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [e.lngLat.lng, e.lngLat.lat]
          },
          properties: {
            id: String(new Date().getTime())
          }
        }

        geojson.features.push(point)
      }

      if (geojson.features.length > 1) {
        linestring.geometry.coordinates = geojson.features.map(
          point => point.geometry.coordinates
        )

        geojson.features.push(linestring)

        // Populate the distanceContainer with total distance
        const value = document.createElement('pre')
        value.classList = ['distance-label']
        const distance = turf.length(linestring)
        value.textContent = `${t(
          'labels.measurement_tool.result'
        )}: ${distance.toLocaleString()}km`
        distanceContainer.appendChild(value)
      }

      map.getSource('geojson').setData(geojson)
    })
    map.on('mousemove', e => {
      if (isMeasureActive.value) {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['measure-points']
        })
        // Change the cursor to a pointer when hovering over a point on the map.
        // Otherwise cursor is a crosshair.
        map.getCanvas().style.cursor = features.length ? 'pointer' : 'crosshair'
      }
    })
  })
}

watch(
  () => props.mapRef,
  newVal => {
    setupMeasurementTool(newVal.map)
  }
)
</script>
<style lang="scss">
.measure {
  position: absolute;
  top: 100px;
  right: 40px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  color: #2b4551;
  background-color: #f1f1f1;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover > & {
    &__tooltip {
      opacity: 1;
    }
  }
  &--active {
    color: #fff;
    background-color: #099ee4;
  }
  &__tooltip {
    position: absolute;
    top: 50%;
    right: 150%;
    padding: 12px 20px;
    color: #fff;
    background-color: #2b4551;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    transform: translateY(-50%);
    z-index: 1000;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 14px;
      height: 14px;
      background-color: #2b4551;
      border-radius: 1.5px;
      transform: translate(-53%, -50%) rotate(45deg);
    }
  }
}
.distance-container {
  position: absolute;
  bottom: 10px;
  right: 70px;
  z-index: 1;
  background-color: #eee;
  border-radius: 4px;
}
.distance-label {
  color: #000;
  padding: 10px;
}
</style>
