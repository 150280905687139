<template>
  <div class="left-panel__header__lang-buttons">
    <a
      :class="{
        'left-panel__header__lang-button': true,
        'left-panel__header__lang-button--active': locale === 'fr'
      }"
      href="/fr"
      @click="langButtonHandler('fr')"
    >
      Fr
    </a>
    <p class="left-panel__header__lang-divider"></p>
    <a
      :class="{
        'left-panel__header__lang-button': true,
        'left-panel__header__lang-button--active': locale === 'en'
      }"
      href="/en"
      @click="langButtonHandler('en')"
    >
      En
    </a>
    <p class="left-panel__header__lang-divider"></p>
    <a
      :class="{
        'left-panel__header__lang-button': true,
        'left-panel__header__lang-button--active': locale === 'in'
      }"
      href="/in"
      @click="langButtonHandler('in')"
    >
      In
    </a>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

const langButtonHandler = lang => {
  window.localStorage.setItem('lang', lang)
}
</script>
