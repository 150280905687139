export default {
  firebase: {
    apiKey: 'AIzaSyD8HJ-35631_tr18EsSVn5R1O61RZM5lCE',
    authDomain: 'geoselec-raster.firebaseapp.com',
    projectId: 'geoselec-raster',
    storageBucket: 'geoselec-raster.appspot.com',
    messagingSenderId: '80297944962',
    appId: '1:80297944962:web:45bb8328935c48ba67119b',
    measurementId: 'G-375B5RD9CM'
  }
}
