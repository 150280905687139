import { getRasterLayersConfig } from '@/commons/lib/apiClient'

export const getVillageSelectorOptions = async lang => {
  const groupByVillage = []

  const rasterLayersConfig = await getRasterLayersConfig()

  rasterLayersConfig.forEach(layer => {
    const layerId = lang === 'in' ? layer.village_in : layer.village
    if (!groupByVillage.length) {
      groupByVillage.push({ id: layerId, data: [layer] })
    } else {
      const villageMosaic = groupByVillage.find(group => group.id === layerId)
      if (villageMosaic) {
        villageMosaic.data.push(layer)
      } else {
        groupByVillage.push({ id: layerId, data: [layer] })
      }
    }
  })

  return groupByVillage
}
