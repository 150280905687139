<template>
  <div class="metadata__container">
    <div class="metadata__header">
      <h4>{{ t('labels.metadata_panel.header') }}</h4>
      <p>{{ t('labels.metadata_panel.village') }}</p>
      <h5>{{ t(`labels.city_names['${cityName}']`) }}</h5>
    </div>
    <div v-if="!isComparisonLayout" class="metadata__list">
      <div
        v-for="(value, propertyName, index) in listItems"
        :key="propertyName"
        class="metadata__list__item"
      >
        <div class="label label-1">
          {{ t(`labels.metadata_panel['${propertyName}']`) }}
        </div>
        <div class="label label-2">
          <div></div>
          <div class="label-2-2">
            <i :class="metadataIcons[index]"></i>
          </div>
          <div></div>
        </div>
        <a
          v-if="propertyName === 'source'"
          class="label-3-a"
          :href="value"
          @click.prevent="copyToClipboard(value)"
          >{{ t(`labels.metadata_panel.copy_url`) }}</a
        >
        <div v-else class="label label-3">
          {{
            t(`labels.metadata_panel['${value}']`) ===
            `labels.metadata_panel['${value}']`
              ? value
              : t(`labels.metadata_panel['${value}']`)
          }}
        </div>
      </div>
    </div>
    <div v-else class="metadata__list">
      <div
        v-for="(value, propertyName, index) in listItems"
        :key="propertyName"
        class="metadata__list__item"
      >
        <a
          v-if="propertyName === 'source'"
          class="label-1-a"
          :href="value.main"
          @click.prevent="copyToClipboard(value.main)"
          >{{ t(`labels.metadata_panel.copy_url`) }}</a
        >
        <div v-else class="label label-1">
          {{
            t(`labels.metadata_panel['${value.main}']`) ===
            `labels.metadata_panel['${value.main}']`
              ? value.main
              : t(`labels.metadata_panel['${value.main}']`)
          }}
        </div>
        <div class="label label-2">
          <div>
            <i class="fas fa-caret-left"></i>
          </div>
          <div class="label-2-2">
            <i :class="metadataIcons[index]"></i>
            <p>{{ t(`labels.metadata_panel['${propertyName}']`) }}</p>
          </div>
          <div>
            <i class="fas fa-caret-right"></i>
          </div>
        </div>
        <a
          v-if="propertyName === 'source'"
          class="label-3-a"
          :href="value.compare"
          @click.prevent="copyToClipboard(value.compare)"
          >{{ t(`labels.metadata_panel.copy_url`) }}</a
        >
        <div v-else class="label label-3">
          {{
            t(`labels.metadata_panel['${value.compare}']`) ===
            `labels.metadata_panel['${value.compare}']`
              ? value.compare
              : t(`labels.metadata_panel['${value.compare}']`)
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  fetchedLayersData: Array,
  mainLayer: Object,
  comparisonLayer: Object,
  isMapComparisonActive: Boolean
})

const { t } = useI18n()
const metadataIcons = [
  'far fa-calendar-alt',
  'fa fa-balance-scale',
  'fa fa-ruler-horizontal',
  'fa fa-grip-horizontal',
  'far fa-user',
  'fa fa-link',
  'fa fa-globe'
]
const cityName = computed(() => {
  return props.fetchedLayersData?.[0].village
})

const isComparisonLayout = computed(() => {
  return props.isMapComparisonActive && props.mainLayer && props.comparisonLayer
})

const listItems = computed(() => {
  if (!props.isMapComparisonActive) {
    const properties = getItemProperties(
      props.mainLayer || props.comparisonLayer
    )
    // Add the "epsg" attribute to the object
    properties.epsg = 3857
    return properties
  } else {
    if (props.mainLayer && props.comparisonLayer) {
      const mainProperties = props.mainLayer
      mainProperties.epsg = 3857

      const comparisonProperties = props.comparisonLayer
      comparisonProperties.epsg = 3857

      return getCompareItemProperties(mainProperties, comparisonProperties)
    } else {
      const properties = getItemProperties(
        props.mainLayer || props.comparisonLayer
      )
      // Add the "epsg" attribute to the object
      properties.epsg = 3857
      return properties
    }
  }
})
const itemsToBeExtracted = [
  'year',
  'scale',
  'resolution',
  'dpi',
  'owner',
  'source',
  'epsg'
]

const getItemProperties = layer => {
  const { year, scale, resolution, dpi, owner, source } = layer
  return { year, scale, resolution, dpi, owner, source }
}
const getCompareItemProperties = (main, compare) => {
  const listItems = {}
  itemsToBeExtracted.forEach(
    item => (listItems[item] = { main: main[item], compare: compare[item] })
  )
  return listItems
}

const copyToClipboard = value => {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      console.log('URL copied to clipboard')
    })
    .catch(error => {
      console.error('Failed to copy URL: ', error)
    })
}
</script>

<style lang="scss" scoped>
.metadata {
  &__container {
    color: #2b4551;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  &__header {
    h4 {
      color: #099ee4;
      margin-bottom: 20px;
      font-weight: 500;
    }
    p {
      color: #7e8b8b;
      font-size: 8px;
      font-weight: 600;
    }
    h5 {
      text-transform: uppercase;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 340px;
    &__item {
      display: grid;
      grid-template-columns: 110px auto 110px;
      padding: 12px 16px;
      background-color: #eef2f1;
      border-bottom: 1px solid #dae0df;
      &:first-child {
        border-top: 1px solid #dae0df;
      }
      .label-3-a {
        text-align: right;
      }
      .label-1-a {
        text-align: left;
      }
      .label {
        place-self: center;
        &-1 {
          place-self: center start;
          font-size: 14px;
        }
        &-2 {
          display: grid;
          grid-template-columns: 10px 60px 10px;
          div {
            place-self: center;
          }
          &-2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 4px;
            i {
              font-size: 22px;
            }
            p {
              color: #7e8b8b;
              font-size: 10px;
              text-align: center;
            }
          }
        }
        &-3 {
          place-self: center end;
          font-size: 14px;
          text-align: right;
        }
      }
    }
  }
}
</style>
