<template>
  <router-view />
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { getUserLanguage } from './lang.js'

const { locale } = useI18n()
locale.value = getUserLanguage()
</script>
