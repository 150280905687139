const cityNames = {
  latin: {
    Kangiqsualujjuaq: 'Kangiqsualujjuaq',
    Kuujjuaq: 'Kuujjuaq',
    Tasiujaq: 'Tasiujaq',
    Aupaluk: 'Aupaluk',
    Kangirsuk: 'Kangirsuk',
    Quaqtaq: 'Quaqtaq',
    Kangiqsujuaq: 'Kangiqsujuaq',
    Salluit: 'Salluit',
    Ivujivik: 'Ivujivik',
    Akulivik: 'Akulivik',
    Puvirnituq: 'Puvirnituq',
    Inukjuak: 'Inukjuak',
    Umiujaq: 'Umiujaq',
    Kuujjuarapik: 'Kuujjuarapik'
  },
  inuk: {
    Kangiqsualujjuaq: 'ᑲᖏᕐᓱᐊᓗᒃᔪᐊᖅ',
    Kuujjuaq: 'ᑯᑦᔪᐊᖅ',
    Tasiujaq: 'ᑕᓯᐅᔭᖅ',
    Aupaluk: 'ᐊᐅᐸᓗ',
    Kangirsuk: 'ᑲᖏᕐᓱᒃ',
    Quaqtaq: 'ᖁᐊᕐᑕᖅ',
    Kangiqsujuaq: 'ᑲᖏᕐᓱᔪᐊᖅ',
    Salluit: 'ᓴᓪᓗᐃᑦ',
    Ivujivik: 'ᐃᕗᔨᕕᒃ',
    Akulivik: 'ᐊᑯᓕᕕᒃ',
    Puvirnituq: 'ᐳᕕᕐᓂᑐᒃ',
    Inukjuak: 'ᐃᓄᑦᔪᐊᒃ',
    Umiujaq: 'ᐅᒥᐅᔭᖅ',
    Kuujjuarapik: 'ᑰᔾᔪᐊᕌᐱᒃ'
  }
}

export default {
  fr: {
    labels: {
      map_search: {
        label: 'Lieu',
        placeholder: 'Village'
      },
      dropdown_year: {
        label: 'Année',
        placeholder: 'Choisir une année'
      },
      comparison_button: {
        open: 'Comparer avec l’année',
        close: 'Fermer'
      },
      geojson_selector_labels: {
        limits: 'Limites des villages nordiques',
        'territories-1': 'Limites des territoires de catégorie 1',
        'territories-2': 'Limites des territoires de catégorie 2'
      },
      copyright: {
        'Gouvernement du Québec': 'Gouvernement du Québec',
        'Gouvernement du Canada':
          'Photo(s) de la Photothèque nationale de l’air. Reproduite(s) avec la permission du Département des ressources naturelles Canada, 2023.'
      },
      metadata_panel: {
        header: 'Métadonnées',
        village: 'Village',
        year: 'Année',
        scale: 'Échelle',
        resolution: 'Résolution',
        dpi: 'PPP (points par pixel)',
        owner: 'Propriétaire',
        source: 'Service Web',
        copy_url: 'Copier le lien (service de tuile XYZ)',
        'Gouvernement du Québec': 'Gouvernement du Québec',
        'Gouvernement du Canada': 'Gouvernement du Canada',
        'hydro-québec': 'Hydro-Québec',
        epsg: 'EPSG'
      },
      measurement_tool: {
        on: 'Outil de mesure : activé',
        off: 'Outil de mesure : désactivé',
        result: 'Distance totale'
      },
      mailto: 'Nous joindre',
      city_names: cityNames.latin
    }
  },
  en: {
    labels: {
      map_search: {
        label: 'Place',
        placeholder: 'Village'
      },
      dropdown_year: {
        label: 'Year',
        placeholder: 'Select a year'
      },
      comparison_button: {
        open: 'Compare with year',
        close: 'Close'
      },
      geojson_selector_labels: {
        limits: 'Boundaries of northern villages',
        'territories-1': 'Boundaries of Category 1 lands',
        'territories-2': 'Boundaries of Category 2 lands'
      },
      copyright: {
        'Gouvernement du Québec': 'Quebec Government',
        'Gouvernement du Canada':
          'National Air Photo Library aerial photo. Reproduced with the permission of the Department of Natural Resources, 2023.'
      },
      metadata_panel: {
        header: 'Metadata',
        village: 'Village',
        year: 'Year',
        scale: 'Scale',
        resolution: 'Resolution',
        dpi: 'DPI (dots per pixel)',
        owner: 'Owner',
        source: 'Web Service',
        copy_url: 'Copy the link (XYZ tile service)',
        'Gouvernement du Québec': 'Quebec Government',
        'Gouvernement du Canada': 'Government of Canada',
        'hydro-québec': 'Hydro-Québec',
        epsg: 'EPSG'
      },
      measurement_tool: {
        on: 'Measurment tool : on',
        off: 'Measurment tool : off',
        result: 'Total distance'
      },
      mailto: 'Contact us',
      city_names: cityNames.latin
    }
  },
  in: {
    labels: {
      map_search: {
        label: 'ᐃᓂᒃ',
        placeholder: 'ᓄᓇᓕᐸᐅᔭᖅ'
      },
      dropdown_year: {
        label: 'ᐊᕌᒍᒃ',
        placeholder: 'ᓇᓗᓀᕐᓯᓂᖅ ᐊᕌᒍᒥᒃ'
      },
      comparison_button: {
        open: 'ᓇᓪᓕᖁᐊᒌᕐᓯᓂᖅ ᐊᕌᒍᒥᒃ',
        close: 'ᐅᒃᑯᐊᑐᖅ'
      },
      geojson_selector_labels: {
        limits: 'ᐊᕙᓗᓕᕐᑐᑕᐅᓯᒪᓂᖏᑦ ᑕᕐᕋᒥ ᓄᓇᓖᑦ',
        'territories-1': 'ᐊᕙᓗᓕᕐᑐᑕᐅᓯᒪᓂᖏᑦ ᑎᑎᕐᑐᑕᐅᒪᔪᑦ ᓄᓀ 1',
        'territories-2': 'ᐊᕙᓗᓕᕐᑐᑕᐅᓯᒪᓂᖏᑦ ᑎᑎᕐᑐᑕᐅᒪᔪᑦ ᓄᓀ 2'
      },
      copyright: {
        'Gouvernement du Québec': 'ᑯᐯ ᑲᕙᒪᖓ',
        'Gouvernement du Canada':
          'ᓄᓇᕐᔪᐊᒥ ᖃᖓᑦᑕᔫᑦ ᐊᑦᔨᖑᐊᓕᐊᖏᓐᓂᒃ ᐊᑦᔨᖑᐊᖃᐅᑎ ᖃᖓᑦᑕᔫᒃᑯᑦ ᐊᑦᔨᓕᐊᕕᓂᖅ. ᓄᐃᒋᐊᓪᓚᑐᖅ ᐊᖏᕈᑕᐅᑦᓱᓂ ᐱᓇᓱᕝᕕᒧᑦ ᓯᓚᒥ ᐱᑦᓴᖁᑎᓂᒃ, 2023-ᒥ.'
      },
      metadata_panel: {
        header: 'ᓄᐊᑕᒪᔪᓕᕇᑦ ᐃᓗᓕᖃᕐᓱᑎᒃ ᓄᐊᑕᒪᔪᓂᒃ',
        village: 'ᓄᓇᓕᒃ',
        year: 'ᐊᕐᕌᒍᒃ',
        scale: 'ᓇᐅᑦᓯᑑᑎᒃ',
        resolution: 'ᑐᑭᑖᕈᑎᒃ',
        dpi: 'ᑏᐲᐁ (ᑕᕐᓵᐲᑦ ᖃᐅᒻᒪᓛᐱᐅᑦᓱᑎᒃ ᐊᔾᔨᖑᐊᓕᐅᕈᑎᓃᓲᑦ)',
        owner: 'ᓇᒻᒥᓂᓕᒃ',
        source: 'Web Service',
        copy_url: 'Copy the link (XYZ tile service)',
        'Gouvernement du Québec': 'ᑯᐯ ᑲᕙᒪᖓ',
        'Gouvernement du Canada': 'ᑳᓇᑕᐅᑉ ᑲᕙᒪᖓ',
        'Hydro-Québec': 'ᐃᑯᒪᓐᓂᐊᑏᒃᑯᑦ-ᑯᐯᒃ',
        epsg: 'EPSG'
      },
      measurement_tool: {
        on: 'ᓱᕐᕌᑎᒍᑎᒃ ᓴᓇᕐᕈᑎᒃ: ᐃᑭᑦᓯᒪᔪᖅ ᐅᕝᕙᓘᓐᓃᑦ',
        off: 'ᓱᕐᕌᑎᒍᑎᒃ ᓴᓇᕐᕈᑎᒃ: ᖃᒥᑦᓯᒪᔪᖅ',
        result: 'ᑲᑎᑦᓱᒋᑦ ᐅᖓᓯᓐᓃᑦ'
      },
      mailto: 'ᖃᐅᔨᒋᐊᕐᕕᒋᑎᒍᑦ',
      city_names: cityNames.inuk
    }
  }
}
