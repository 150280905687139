import axios from 'axios'

export async function getRasterLayersConfig() {
  try {
    const res = await axios.get(
      'https://takuvuq-assets.s3.ca-central-1.amazonaws.com/raster-layers-config.json'
    )
    return res.data
  } catch (err) {
    console.log(err)
  }
}
