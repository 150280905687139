import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { public: true }
  },
  {
    path: '/fr',
    name: 'Home-fr',
    component: Home,
    meta: { public: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/en',
    name: 'Home-en',
    component: Home,
    meta: { public: true, lang: 'en' },
    props: { lang: 'en' }
  },
  {
    path: '/in',
    name: 'Home-in',
    component: Home,
    meta: { public: true, lang: 'in' },
    props: { lang: 'in' }
  },
  {
    path: '/protected',
    name: 'Protected',
    component: Home
    // meta: { public: false }
  }
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes
})

export default router
