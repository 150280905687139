<template>
  <div id="comparison-container">
    <div id="mapLeft" class="map"></div>
    <div id="mapRight" class="map"></div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import 'maplibre-gl/dist/maplibre-gl.css'
import maplibregl from 'maplibre-gl'
import '@maplibre/maplibre-gl-compare/dist/maplibre-gl-compare.css'
import MaplibreCompare from '@maplibre/maplibre-gl-compare'
import vectorLayersConfig from '@/vector-layers-config.json'

const props = defineProps({
  token: {
    type: String,
    default: null
  },
  rasterLayers: {
    type: Array,
    default: () => []
  },
  selectedMainRasterLayer: {
    type: Object,
    default: () => {}
  },
  selectedComparisonRasterLayer: {
    type: Object,
    default: () => {}
  },
  selectedVectorLayer: {
    type: Array,
    default: () => []
  }
})
const mapLeftRef = ref(null)
const mapRightRef = ref(null)

const defaultMapOptions = {
  center: { lng: -78.18144, lat: 60.80826 },
  style: 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
  zoom: 13,
  maxZoom: 20,
  attributionControl: false,
  hash: true
}

onMounted(() => {
  createMap(mapLeftRef, mapRightRef, 'mapLeft', 'mapRight')

  const mapLeft = mapLeftRef.value
  const mapRight = mapRightRef.value
  mapLeft.on('load', () => {
    addVectorLayers(mapLeft, props.selectedVectorLayer)
    addRasterLayers(mapLeft, props.rasterLayers, props.selectedMainRasterLayer)
  })
  mapRight.on('load', () => {
    addVectorLayers(mapRight, props.selectedVectorLayer)
    addRasterLayers(
      mapRight,
      props.rasterLayers,
      props.selectedComparisonRasterLayer
    )
  })
})

watch(
  () => props.rasterLayers,
  (newVal, oldVal) => {
    if (oldVal.length) {
      removeRasterLayers(mapLeftRef.value, oldVal)
      removeRasterLayers(mapRightRef.value, oldVal)
    }
    if (newVal.length) {
      addRasterLayers(mapLeftRef.value, newVal)
      addRasterLayers(mapRightRef.value, newVal)
    }
  }
)

watch(
  () => props.selectedMainRasterLayer,
  (newVal, oldVal) => {
    updateRasterLayersVisibility(mapLeftRef.value, newVal, oldVal)
  }
)
watch(
  () => props.selectedComparisonRasterLayer,
  (newVal, oldVal) => {
    updateRasterLayersVisibility(mapRightRef.value, newVal, oldVal)
  }
)
watch(
  () => props.selectedVectorLayer,
  (newVal, oldVal) => {
    updateVectorLayerVisibility(mapLeftRef.value, newVal, oldVal)
    updateVectorLayerVisibility(mapRightRef.value, newVal, oldVal)
  }
)

const createMap = (
  mapLeft,
  mapRight,
  mapLeftContainerId,
  mapRightContainerId
) => {
  mapLeft.value = new maplibregl.Map({
    ...defaultMapOptions,
    container: mapLeftContainerId,
    transformRequest
  })
  mapRight.value = new maplibregl.Map({
    ...defaultMapOptions,
    container: mapRightContainerId,
    transformRequest
  })

  // eslint-disable-next-line no-new
  return new MaplibreCompare(
    mapLeft.value,
    mapRight.value,
    '#comparison-container'
  )
}

const addRasterLayers = (map, layers, defaultVisibleLayer) => {
  if (!layers.length) return

  const flyToOptions = {
    Akulivik: { center: [-78.156, 60.8184], zoom: 11.44 },
    Aupaluk: { center: [-69.5942, 59.3179], zoom: 10.17 },
    Inukjuak: { center: [-78.1222, 58.4767], zoom: 10.56 },
    Ivujivik: { center: [-77.8552, 62.4046], zoom: 11.25 },
    Kangiqsualujjuaq: { center: [-65.938, 58.7015], zoom: 11.3 },
    Kangiqsujuaq: { center: [-71.9454, 61.5943], zoom: 11.28 },
    Kangirsuk: { center: [-69.9876, 60.0122], zoom: 11.03 },
    Kuujjuaq: { center: [-68.3841, 58.1134], zoom: 11.22 },
    Kuujjuarapik: { center: [-77.74659, 55.27728], zoom: 11.96 },
    Puvirnituq: { center: [-77.2536, 60.039], zoom: 11.24 },
    Quaqtaq: { center: [-69.6198, 61.0417], zoom: 11.72 },
    Salluit: { center: [-75.6241, 62.1948], zoom: 10.94 },
    Tasiujaq: { center: [-69.9116, 58.7088], zoom: 11.02 },
    Umiujaq: { center: [-76.5191, 56.5413], zoom: 11.96 }
  }

  map.jumpTo(flyToOptions[layers[0].village])

  const defaultLayerId = defaultVisibleLayer
    ? `pictures-raster-comparison-${defaultVisibleLayer.village}-${defaultVisibleLayer.year}`
    : null

  layers.forEach(layer => {
    const layerId = `pictures-raster-comparison-${layer.village}-${layer.year}`
    const isLayerVisible = layerId === defaultLayerId
    const sourceId = `${layerId}-comparison-source`
    map.addSource(sourceId, {
      type: 'raster',
      tiles: [layer.source]
    })
    map.addLayer(
      {
        id: layerId,
        type: 'raster',
        source: sourceId,
        layout: {
          visibility: isLayerVisible ? 'visible' : 'none'
        }
      },
      'territories-2'
    )
  })
}

function removeRasterLayers(map, layers) {
  layers.forEach(layer => {
    const layerId = `pictures-raster-comparison-${layer.village}-${layer.year}`
    map.removeLayer(layerId)
    map.removeSource(`${layerId}-comparison-source`)
  })
}

function updateRasterLayersVisibility(map, newLayer, oldLayer) {
  if (oldLayer) {
    const layerId = `pictures-raster-comparison-${oldLayer.village}-${oldLayer.year}`
    if (map.getLayer(layerId)) {
      map.setLayoutProperty(layerId, 'visibility', 'none')
    }
  }
  if (newLayer) {
    const layerId = `pictures-raster-comparison-${newLayer.village}-${newLayer.year}`
    if (map.getLayer(layerId)) {
      map.setLayoutProperty(layerId, 'visibility', 'visible')
    }
  }
}

const addVectorLayers = (map, defaultVisibleLayers) => {
  vectorLayersConfig.forEach(({ layer, source, after }) => {
    const isLayerVisible = defaultVisibleLayers.includes(layer.id)
    map.addSource(`${layer.id}-source`, source)
    map.addLayer(
      {
        ...layer,
        layout: {
          visibility: isLayerVisible ? 'visible' : 'none'
        }
      },
      after
    )
  })
}

function updateVectorLayerVisibility(map, newLayers, oldLayers) {
  if (oldLayers.length) {
    oldLayers.forEach(layerId => {
      map.setLayoutProperty(layerId, 'visibility', 'none')
    })
  }
  if (newLayers.length) {
    newLayers.forEach(layerId => {
      map.setLayoutProperty(layerId, 'visibility', 'visible')
    })
  }
}

const transformRequest = (url, resourceType) => {
  if (!process.env.VUE_APP_PROTECTED_TILES_URL) return
  if (
    (resourceType === 'Tile' &&
      url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)) ||
    url.startsWith(process.env.VUE_APP_PROTECTED_RASTER_URL)
  ) {
    return {
      url: url,
      headers: { Authorization: `Bearer ${props.token}` }
    }
  }
}

defineExpose({
  mapLeftRef,
  mapRightRef,
  defaultMapOptions
})
</script>
<style>
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.copyright-gouv-qc {
  position: absolute;
  bottom: 10px;
  width: 186px;
  right: calc(50vw - 93px);
  z-index: 1;
  text-align: center;
}
</style>
