import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from './messages'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'
import 'normalize.css'
import '@/assets/styles/main.scss'
import { createPinia } from 'pinia'
const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  legacy: false,
  messages
})

createApp(App)
  .use(createPinia())
  .use(router)
  .use(
    VueGtag,
    {
      appName: 'geoselec-raster',
      pageTrackerScreenviewEnabled: true,
      config: { id: 'G-4BYW6F0X61' }
    },
    router
  )
  .use(i18n)
  .mount('#app')
