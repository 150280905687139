<template>
  <div>
    <gl-map
      ref="mapRef"
      :init-map-position="options"
      :element-style="{
        width: 'calc(100vw - 60px)',
        height: '100vh',
        marginLeft: '60px'
      }"
    />
    <MeasurementTool :map-ref="mapRef" />
    <div
      v-if="
        currentLayer[0] && currentLayer[0].owner === 'Gouvernement du Québec'
      "
      class="copyright-gouv-qc"
    >
      © {{ t(`labels.copyright['Gouvernement du Québec']`) }}
    </div>
    <div
      v-else-if="
        currentLayer[0] && currentLayer[0].owner === 'Gouvernement du Canada'
      "
      class="copyright-gouv-can"
    >
      {{ t(`labels.copyright['Gouvernement du Canada']`) }}
    </div>
  </div>
</template>

<script setup>
import { shallowRef, onMounted, watch, ref } from 'vue'
import GlMap from '@anagraph/gl-map'
import MeasurementTool from './measurement-tool.vue'
import vectorLayersConfig from '@/vector-layers-config.json'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  token: {
    type: String,
    default: null
  },
  rasterLayers: {
    type: Array,
    default: () => []
  },
  selectedMainRasterLayer: {
    type: Object,
    default: () => {}
  },
  selectedVectorLayer: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['loaded'])

const mapRef = shallowRef(null)
const currentLayer = ref([])

onMounted(() => {
  const map = mapRef.value.map
  currentLayer.value[0] = props.selectedMainRasterLayer
  map.on('load', () => {
    addVectorLayers(map, props.selectedVectorLayer)
    addRasterlayers(map, props.rasterLayers, props.selectedMainRasterLayer)

    emit('loaded', true)
  })
})

watch(
  () => props.rasterLayers,
  (newVal, oldVal) => {
    if (oldVal.length) {
      removeRasterLayers(mapRef.value.map, oldVal)
    }
    if (newVal.length) {
      addRasterlayers(mapRef.value.map, newVal)
    }
  }
)

watch(
  () => props.selectedVectorLayer,
  (newVal, oldVal) => {
    updateVectorLayerVisibility(mapRef.value.map, newVal, oldVal)
  }
)

watch(
  () => props.selectedMainRasterLayer,
  (newVal, oldVal) => {
    updateRasterLayersVisibility(mapRef.value.map, newVal, oldVal)
  }
)

function addRasterlayers(map, layers, defaultVisibleLayer) {
  if (!layers.length) return

  const flyToOptions = {
    Akulivik: { center: [-78.156, 60.8184], zoom: 11.44 },
    Aupaluk: { center: [-69.5942, 59.3179], zoom: 10.17 },
    Inukjuak: { center: [-78.1222, 58.4767], zoom: 10.56 },
    Ivujivik: { center: [-77.8552, 62.4046], zoom: 11.25 },
    Kangiqsualujjuaq: { center: [-65.938, 58.7015], zoom: 11.3 },
    Kangiqsujuaq: { center: [-71.9454, 61.5943], zoom: 11.28 },
    Kangirsuk: { center: [-69.9876, 60.0122], zoom: 11.03 },
    Kuujjuaq: { center: [-68.3841, 58.1134], zoom: 11.22 },
    Kuujjuarapik: { center: [-77.74659, 55.27728], zoom: 11.96 },
    Puvirnituq: { center: [-77.2536, 60.039], zoom: 11.24 },
    Quaqtaq: { center: [-69.6198, 61.0417], zoom: 11.72 },
    Salluit: { center: [-75.6241, 62.1948], zoom: 10.94 },
    Tasiujaq: { center: [-69.9116, 58.7088], zoom: 11.02 },
    Umiujaq: { center: [-76.5191, 56.5413], zoom: 11.96 }
  }

  map.jumpTo(flyToOptions[layers[0].village])

  const defaultLayerId = defaultVisibleLayer
    ? `pictures-raster-${defaultVisibleLayer.village}-${defaultVisibleLayer.year}`
    : null

  layers.forEach(layer => {
    const layerId = `pictures-raster-${layer.village}-${layer.year}`
    const isLayerVisible = layerId === defaultLayerId
    const sourceId = `${layerId}-source`
    map.addSource(sourceId, { type: 'raster', tiles: [layer.source] })
    map.addLayer(
      {
        id: layerId,
        type: 'raster',
        source: sourceId,
        layout: {
          visibility: isLayerVisible ? 'visible' : 'none'
        }
      },
      'territories-2'
    )
  })
}

function removeRasterLayers(map, layers) {
  layers.forEach(layer => {
    const layerId = `pictures-raster-${layer.village}-${layer.year}`
    map.removeLayer(layerId)
    map.removeSource(`${layerId}-source`)
  })
}

function updateRasterLayersVisibility(map, newLayer, oldLayer) {
  currentLayer.value[0] = newLayer
  if (oldLayer) {
    const layerId = `pictures-raster-${oldLayer.village}-${oldLayer.year}`
    if (map.getLayer(layerId)) {
      map.setLayoutProperty(layerId, 'visibility', 'none')
    }
  }
  if (newLayer) {
    const layerId = `pictures-raster-${newLayer.village}-${newLayer.year}`
    if (map.getLayer(layerId)) {
      map.setLayoutProperty(layerId, 'visibility', 'visible')
    }
  }
}

const addVectorLayers = (map, defaultVisibleLayers) => {
  vectorLayersConfig.forEach(({ layer, source, after }) => {
    const isLayerVisible = defaultVisibleLayers.includes(layer.id)
    map.addSource(`${layer.id}-source`, source)
    map.addLayer(
      {
        ...layer,
        layout: {
          visibility: isLayerVisible ? 'visible' : 'none'
        }
      },
      after
    )
  })
}

function updateVectorLayerVisibility(map, newLayers, oldLayers) {
  if (oldLayers.length) {
    oldLayers.forEach(layerId => {
      map.setLayoutProperty(layerId, 'visibility', 'none')
    })
  }
  if (newLayers.length) {
    newLayers.forEach(layerId => {
      map.setLayoutProperty(layerId, 'visibility', 'visible')
    })
  }
}

const transformRequest = (url, resourceType) => {
  if (!process.env.VUE_APP_PROTECTED_TILES_URL) return
  if (
    (resourceType === 'Tile' &&
      url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)) ||
    url.startsWith(process.env.VUE_APP_PROTECTED_RASTER_URL)
  ) {
    return {
      url: url,
      headers: { Authorization: `Bearer ${props.token}` }
    }
  }
}

const options = {
  center: { lng: -69.65922, lat: 59.01991 },
  zoom: 5,
  maxZoom: 20,
  hash: true,
  transformRequest
}

defineExpose({
  mapRef
})
</script>
<style lang="scss">
.copyright-gouv-qc {
  position: absolute;
  bottom: 10px;
  width: 186px;
  right: calc(50vw - 93px);
  z-index: 1;
  text-align: center;
}
.copyright-gouv-can {
  position: absolute;
  bottom: 10px;
  width: 400px;
  right: calc(50vw - 200px);
  z-index: 1;
  text-align: center;
}
</style>
