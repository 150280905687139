<template>
  <left-panel-layout
    v-model:selected-tab="currentTab"
    class="mypanel"
    :is-left-panel-open="mapLoaded"
    :tabs="tabs"
  >
    <template #left-panel>
      <div v-if="mapLoaded">
        <div class="left-panel__container">
          <header-component />
          <div v-if="currentTab === 'pictures-component'">
            <div class="left-panel__localisation">
              <div class="left-panel__localisation__label">
                {{ t('labels.map_search.label') }}
              </div>
              <vue-select
                v-if="map"
                v-model="selectedVillage"
                :options="villageSelectorOptions"
                label="id"
                :placeholder="t('labels.map_search.placeholder')"
              />
            </div>
            <div class="left-panel__divider"></div>
            <div class="main-layer__label">
              {{ t('labels.dropdown_year.label') }}
            </div>
            <vue-select
              v-model="selectedMainRasterLayer"
              :options="rasterLayers"
              label="year"
              :placeholder="t('labels.dropdown_year.placeholder')"
              :class="{
                'main-layer__vue-select': true,
                'main-layer__vue-select--disabled': !rasterLayers.length
              }"
            />
            <button
              v-if="rasterLayers.length"
              class="left-panel__comparison-button"
              @click="toggleMapComparison"
            >
              {{
                isMapComparisonActive
                  ? t('labels.comparison_button.close')
                  : t('labels.comparison_button.open')
              }}
            </button>
            <p v-if="isMapComparisonActive" class="comparison-layer__label">
              {{ t('labels.dropdown_year.label') }}
            </p>
            <vue-select
              v-if="isMapComparisonActive"
              v-model="selectedComparisonRasterLayer"
              :options="rasterLayers"
              label="year"
              :placeholder="t('labels.dropdown_year.placeholder')"
              class="comparison-layer__vue-select"
            >
            </vue-select>
          </div>
          <div v-else class="vector-layers__selectors">
            <div
              v-for="({ layer }, index) in vectorLayersConfig"
              :key="index"
              class="vector-layers__selector"
            >
              <input
                :id="layer.id"
                v-model="selectedVectorLayer"
                type="checkbox"
                :name="layer.id"
                :value="layer.id"
              />
              <label :for="layer.id">{{
                t(`labels.geojson_selector_labels['${layer.id}']`)
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div v-else>loading ...</div>
    </template>
    <template #map>
      <div>
        <div v-if="!isMapComparisonActive">
          <Map
            ref="mapComponent"
            :token="token"
            :raster-layers="rasterLayers"
            :selected-main-raster-layer="selectedMainRasterLayer"
            :selected-vector-layer="selectedVectorLayer"
            @loaded="mapLoaded = true"
          />
        </div>
        <div v-else>
          <MapCompare
            :token="token"
            :raster-layers="rasterLayers"
            :selected-main-raster-layer="selectedMainRasterLayer"
            :selected-comparison-raster-layer="selectedComparisonRasterLayer"
            :selected-vector-layer="selectedVectorLayer"
          />
        </div>
      </div>
    </template>
  </left-panel-layout>
  <img src="@/assets/images/geoselec_logo.png" class="home__logo" />
  <span class="mail">
    <a @click="handleContactClick">
      <i class="fas fa-envelope"></i>
    </a>
    <span class="mail__tooltip">{{ t('labels.mailto') }}</span>
  </span>
  <i
    :class="{
      'fas fa-layer-group': true,
      'right-panel__toggler': true,
      'right-panel__toggler--active': isPanelMounted && isPanelShow
    }"
    @click="rightPanelToggleHandler"
  ></i>
  <panel v-if="isPanelMounted" v-show="isPanelShow" class="right-panel">
    <feature-list
      :token="token"
      :map-ref="map"
      :fetched-layers-data="rasterLayers"
      :main-layer="selectedMainRasterLayer"
      :comparison-layer="selectedComparisonRasterLayer"
      :is-map-comparison-active="isMapComparisonActive"
      @loaded="mapLoaded = true"
    />
  </panel>
  <div class="kativik-logo">
    <img src="@/assets/images/krg-logo.png" alt="" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { event } from 'vue-gtag'
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import LeftPanelLayout from '@anagraph/left-panel-layout'
import HeaderComponent from '@/components/header-component'
import Map from '@/components/Map'
import MapCompare from '@/components/map-compare.vue'
import FeatureList from '@/components/feature-list.vue'
import { getToken } from '@/commons/auth/firebase-auth'
import { getVillageSelectorOptions } from '@/rasterLayers'
import { useI18n } from 'vue-i18n'
import vectorLayersConfig from '@/vector-layers-config.json'
const tabs = [
  { id: 'pictures-component', icon: 'fa-search' },
  { id: 'boundaries-component', icon: 'fas fa-layer-group' }
]

const { t, locale } = useI18n()
event('homepage', {
  event_label: 'homepage-reached'
})

const token = ref(null)
const mapLoaded = ref(false)
const currentTab = ref('pictures-component')
const map = ref(null)
const mapComponent = ref(null)

const villageSelectorOptions = ref([])

const selectedVillage = ref(null)
const rasterLayers = computed(() =>
  selectedVillage.value ? selectedVillage.value.data : []
)

const selectedMainRasterLayer = ref(null)
const selectedComparisonRasterLayer = ref(null)

const selectedVectorLayer = ref([])

const isMapComparisonActive = ref(false)
const isPanelShow = ref(false)
const isPanelMounted = computed(() => {
  if (rasterLayers.value.length) {
    if (isMapComparisonActive.value) {
      if (
        selectedMainRasterLayer.value ||
        selectedComparisonRasterLayer.value
      ) {
        return true
      } else {
        return false
      }
    } else {
      if (selectedMainRasterLayer.value) {
        return true
      } else {
        return false
      }
    }
  } else {
    return false
  }
})

// If the mailto doesn't open a mail application, it will open the Geoselec contact page.
const handleContactClick = () => {
  const url =
    locale.value === 'in'
      ? `https://geoselec.com/en/contact`
      : `https://geoselec.com/${locale.value}/contact`
  window.open(url, '_blank')

  window.location.href = 'mailto:info@geoselec.com'
}

const rightPanelToggleHandler = () => {
  isPanelShow.value = !isPanelShow.value
}

const toggleMapComparison = () => {
  isMapComparisonActive.value = !isMapComparisonActive.value
}

watch(rasterLayers, newVal => {
  if (newVal.length) {
    selectedMainRasterLayer.value = newVal[0]
    selectedComparisonRasterLayer.value = newVal[0]
    isPanelShow.value = window.innerWidth >= 1000
  } else {
    selectedMainRasterLayer.value = null
    selectedComparisonRasterLayer.value = null
    isMapComparisonActive.value = false
    isPanelShow.value = false
  }
})

onMounted(async () => {
  token.value = await getToken()
  map.value = mapComponent.value.mapRef.map
  villageSelectorOptions.value = await getVillageSelectorOptions(locale.value)
})
</script>
<style lang="scss">
.vs__dropdown-menu {
  max-height: 200px;
}
.home {
  &__logo {
    position: absolute;
    bottom: -60px;
    left: 0;
    background-color: #099ee4;
    z-index: 100000;
    transform: rotate(270deg);
    transform-origin: top left;
    height: 60px;
    padding: 18px 47px;
  }
}
.mypanel {
  .left-panel {
    &__panel {
      width: 380px;
      height: initial;
    }
    &__container {
      padding: 40px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
      &__title {
        color: #2b4551;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        overflow: hidden;
        img {
          width: 80%;
          min-width: 180px;
          transform: translateX(-10px);
        }
      }
      &__lang-buttons {
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
      }
      &__lang-button {
        color: #617179;
        text-decoration: none;
        cursor: pointer;
        font-size: 14px;
        &--active {
          color: #099ee4;
        }
      }
      &__lang-divider {
        width: 1px;
        height: 30%;
        margin: auto;
        background-color: #dbdbdb;
      }
    }
    &__localisation {
      margin-top: 23px;
      &__label {
        margin-bottom: 5px;
        color: #7e8b8b;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
      }
      .search {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        color: #2b4551;
        font-weight: 500;
        margin-top: 5px;
        box-shadow: unset;
        border: 1px solid #dae0df;
        border-radius: 2px;
        &__box {
          column-gap: 12px;
          padding: 16px 12px;
        }
        &__separator {
          display: none;
        }
        &__icon {
          padding: 0;
          color: #dae0df;
          font-size: 12px;
          opacity: 1;
          &--clear {
            display: none;
          }
        }
        &__input {
          margin: 0;
          color: #2b4551;
          font-size: 14px;
          font-weight: 500;
          &::placeholder {
            color: #2b4551;
          }
        }
        &__suggestions {
          top: 101%;
        }
        &__suggestion {
          color: #2b4551;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          &:first-of-type::before {
            display: none;
          }
          &:not(:last-of-type)::after {
            display: none;
          }
        }
      }
    }
    .main-layer {
      &__label {
        margin-top: 30px;
        margin-bottom: 5px;
        color: #7e8b8b;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        text-transform: uppercase;
      }
      &__vue-select {
        &--disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    .comparison-layer {
      &__label {
        margin-bottom: 5px;
        color: #7e8b8b;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        text-transform: uppercase;
      }
      &__vue-select {
        &--disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    &__comparison {
      &-button {
        width: 100%;
        margin: 20px 0;
        padding: 10px 20px;
        background-color: #099ee4;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: #4eb5e5;
        }
        &:active {
          background-color: #099ee4;
        }
      }
      &-label {
        color: #7e8b8b;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        text-transform: uppercase;
      }
    }
    &__divider {
      width: 100%;
      height: 2px;
      background-color: #dae0df;
      margin-top: 40px;
    }
    &__toggler {
      color: #2b4551;
    }
  }
  .panel {
    min-height: unset;
    overflow: visible;
  }
}

.v-select,
.vs--open {
  color: #2b4551;
  font-size: 14px;
  font-weight: 500;
  .vs__search {
    padding: 9px 17px;
  }
}
.vs__search::placeholder {
  color: #2b4551;
  font-size: 14px;
  font-weight: 500;
}
.vs__actions {
  padding-right: 17px;
}
.vs__selected {
  margin-left: 10px;
  padding: 5px 11px;
  color: #7e8b8b;
  background-color: #eef2f1;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  border: none;
  border-radius: 13px;
  outline: none;
  &-options {
    align-items: center;
  }
}

.right-panel {
  // TODO: change me
  position: absolute;
  background-color: white;
  z-index: 500;
  top: 40px;
  right: 90px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 18px -2px rgba(0, 0, 0, 0.2);
  &__toggler {
    position: absolute;
    top: 40px;
    right: 40px;
    padding: 12px;
    color: #2b4551;
    background-color: #f1f1f1;
    border-radius: 10px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    &--active {
      color: #fff;
      background-color: #099ee4;
    }
  }
}

.vector-layers {
  &__selectors {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__selector {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #2b4551;
    label {
      cursor: pointer;
    }
  }
}

.mail {
  position: absolute;
  bottom: 285px;
  left: 22px;
  z-index: 100000;
  cursor: pointer;
  a {
    color: #616161;
    text-decoration: none;
  }
  &:hover > & {
    &__tooltip {
      opacity: 1;
    }
  }
  &__tooltip {
    position: absolute;
    top: 50%;
    left: 250%;
    padding: 12px 20px;
    width: max-content;
    color: #fff;
    background-color: #2b4551;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    transform: translateY(-50%);
    z-index: 1000;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 2%;
      width: 14px;
      height: 14px;
      background-color: #2b4551;
      border-radius: 1.5px;
      transform: translate(-53%, -50%) rotate(45deg);
    }
  }
}
.kativik-logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  img {
    max-width: 50px;
    min-width: 20px;
  }
}
</style>
